<template id="find">
    <div align="center">
      <div class="card col-md-8 rounded shadow-lg" style="text-align: left">
        <div class="card-body">
          <div class="card-title">
            <h1>Buscar solicitud</h1>
          </div>
          <br>
          <form v-on:submit.prevent="findPqrs">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-12 col-form-label">Escriba el código de radicación que le fue suministrado en el momento de la creación de su PQRS</label>
                  <input type="text" class="form-control col-md-12" v-model="rad" pattern="[r,R]-[0-9]{4}-[0-9]{1,5}" placeholder="R-AAAA-0000" title="R-AAAA-0000" required>    
                </div>      
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-form-label col-md-12">Número de identificación con el que registró su PQRS</label>
                  <input type="number" class="form-control col-md-12" v-model="id" placeholder="000000000" title="Escriba sólo números" required>
                  </div>      
              </div>
            </div>
            <br>
            <button type="submit" class="btn btn-primary">Buscar</button>  
            <br>
            <br> 
            <div v-if="finding" style="text-align: center" class="alert alert-info" role="alert">
              Buscando, espere por favor..
            </div>   
          </form>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  data(){
    return {
      rad: '',
      id: '',
      finding: false
    }
  },
  created(){
    
  },
  methods: {
    findPqrs(){
      this.finding = true;
      let uri = "/docs/findpqrs";
      this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'rad': this.rad, 'id': this.id })
      .then((response) => {
        var pqrs = response.data;
        pqrs.rad =  pqrs.rad ? pqrs.rad : this.rad;
        pqrs.rad_query = String(this.rad).toUpperCase()

        if (pqrs.dcm_id_rs){
          pqrs.est_nmbre = "Solicitud respondida.";
          this.$router.replace({ name: 'ResultPqrs', params: { 'pqrs': pqrs } });            
        } else {
          /*
          if (pqrs.seguimientos.length > 0){
            let uri = "/estados/edit/" + pqrs.seguimientos[0].EstadoId;
            this.axios.get(uri)
            .then((response) => {
              pqrs.est_nmbre = response.data.Nombre;
              this.$router.replace({ name: 'ResultPqrs', params: { 'pqrs': pqrs } });  
            })
            .catch((err) => {
              this.$router.replace({ name: 'ErrorPqrs' });
            });
          } else {
            pqrs.est_nmbre = "En trámite.";
            this.$router.replace({ name: 'ResultPqrs', params: { 'pqrs': pqrs } });  
          }
          */
          pqrs.est_nmbre = "En trámite.";
          this.$router.replace({ name: 'ResultPqrs', params: { 'pqrs': pqrs } });  
        }
      })
      .catch((err) => {
        this.$router.replace({ name: 'ErrorPqrs' });
      })
    }
  }    
}
</script>
